/* Hide all elements except <p> and <blockquote> */
.paragraph-only *:not(p):not(blockquote) {
  display: none;
}

/* Set font size for <p> and <blockquote> */
.paragraph-only p,
.paragraph-only blockquote {
  display: block;
  font-size: 14px;
}
