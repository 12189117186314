body {
  margin: 0;
  /* src: url("./fonts/ttf/montserrat-cyrillic-200-normal.ttf"); */
  font-family: "Montserrat";
  /* font-size: 22px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300);

h1 {
  font-size: 1.5em;
  margin-bottom: 55px;
}

/* #social-platforms {
  position: relative;
  top: 100px;
  top: 25vh;
  font-size: 1rem;
  text-align: center;
  height: 250px;
  overflow: hidden;
} */

/*Pen code from this point on*/
.btn {
  clear: both;
  white-space: nowrap;
  font-size: 0.75em;
  display: flex;
  border-radius: 5px;
  /* box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35); */
  margin: 2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  font-weight: bold;
}

.btn>span,
.btn-icon>i {
  float: left;
  padding: 12px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 1em;
}

.btn>span {
  padding: 14px 18px 16px;
  white-space: nowrap;
  color: #fff;
  /* background: #b8b8b8; */
}

.btn:focus>span {
  /* background: #9a9a9a; */
}

.btn-icon>i {
  border-radius: 5px 0 0 5px;
  position: relative;
  width: 13px;
  text-align: center;
  font-size: 1.25em;
  color: #fff;

  /* background: #212121; */
}

.btn-fb>i {
  background-color: #3b5998 !important;
}

.btn-twitter>i {
  background-color: #1da1f2;
}

.bi-linkedin {
  background: #007bb6;
}

.bi-whatsapp {
  background: #25d366;
}

.btn-fb>i:hover {
  background-color: #366cdd;
}

.btn-icon:hover>i,
.btn-icon:focus>i {
  /* color: #fff; */
}

.btn-icon>span {
  border-radius: 0 5px 5px 0;
}

.btn-facebook>span {
  background: #3b5998;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  height: 11px;
  cursor: pointer;
}

.btn-facebook>i {
  background: #3b5998;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-facebook:hover>i {
  background: #366cdd;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-facebook:hover>span {
  background: #366cdd;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

/*Twitter*/
.btn-twitter:hover>i,
.btn-twitter:focus>i {
  /* color: #ffffff; */
}

.btn-twitter>span {
  background: #1da1f2;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  height: 11px;
  cursor: pointer;
}

.btn-twitter>i {
  /* height: 16px; */
}

.btn-twitter:hover i {
  background: #00cfff;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-twitter:hover span {
  background: #00cfff;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-linkedin>span {
  background: #0076b4;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  height: 11px;
  cursor: pointer;
}

.btn-linkedin:hover>i {
  background: #009aff;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-linkedin:hover>span {
  background: #009aff;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-whatsapp>span {
  background: #25d266;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  height: 11px;
  cursor: pointer;
}

.btn-whatsapp:hover>i {
  background: #00ff54;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

.btn-whatsapp:hover>span {
  background: #00ff54;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  cursor: pointer;
}

/*LinkedIn*/
.btn-linkedin:hover>i,
.btn-linkedin:focus>i {
  color: #ffffff;
}

/* .class-one:hover .class-one,
.class-one:hover .class-two {
background-color: red;
} */

/* Media Query */

/* For 900px*/

@media screen and (max-width: 900px) {
  .parsed-html img {
    width: 50%;
  }
}

/* For 600px */

@media screen and (max-width: 600px) {
  .parsed-html img {
    width: 100%;
  }
}

/* For 400px */

@media screen and (max-width: 400px) {
  .btn-whatsapp {
    width: 142px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  }

  .btn-facebook {
    width: 138px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  }

  .btn-twitter {
    width: 140px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  }

  .btn-linkedin {
    width: 137px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
  }

  .btn-twitter>span {
    width: 65px;
  }

  .btn-facebook>span {
    width: 63px;
  }

  .btn-linkedin>span {
    width: 62px;
  }
}

.MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list {
  background-color: red;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* code {
  font-family: "Montserrat Variable !important", sans-serif;
} */