#app {
  height: 100%;
}

html,
/* body {
  position: relative;
  height: 50%;
} */



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  height: 100%;
  object-fit: contain;
  width: 100%;
  cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff;
  padding: 0px 20px;
}

.swiper-button-prev {
  left: 35px;
}

.swiper-button-next {
  right: 35px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}

/* For 600px */

@media screen and (max-width: 600px) {}