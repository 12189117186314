.nav-link:focus {
  color: #ec2028;
}

.slide {
  clear: both;
  width: 100%;
  /* height: 0px; */
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
}

.slide li {
  padding: 30px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .slide {
  height: 300px;
}

.css-m6s2pf-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  transform: scaleY(1) !important;
  max-height: 1000vmax !important;
  animation: hide-scroll 1s backwards !important;
}

.css-rllyg0-MuiButtonBase-root-MuiButton-root {
  color: #ec2028 !important;
}

@keyframes hide-scroll {
  0%,
  100% {
    overflow: hidden;
  }
}
