.footer-link {
  color: #ffffff;
  /* text-decoration: none; */
}

.footer-link:hover {
  color: #ec2028 !important;
}

/* Media query */
