/* Hide all elements except <p>, <blockquote>, and <a> */
.paragraph-only *:not(p):not(blockquote):not(a):not(table) {
  display: none;
}

/* Set font size for <p>, <blockquote>, and <a> */
.paragraph-only p,
.paragraph-only blockquote,
.paragraph-only a {
  display: block;
  font-size: 14px;
}
